import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IczRoutes} from '|shared';
import {
  EsslComponentTasksComponent
} from './essl-component-tasks.component';

const routes: IczRoutes = [{path: '', component: EsslComponentTasksComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EsslComponentTasksRoutingModule {}
