@if (dataSource && circulationTableView) {
  <icz-circulation-table
    class="grow"
    [viewType]="circulationTableView"
    [dataSource]="dataSource"
    [openQuickPreview]="false"
    [tableId]="tableId!"
    [isUnitView]="isUnitView"
    (pageLoadStarted)="refreshCounters($event)"
  >
    <div tableTitle class="row grow items-center">
      <icz-label [label]="isUnitView ? 'Útvar - Podpisová kniha' : 'Podpisová kniha'"></icz-label>
      @if (isUnitView) {
        <icz-form-autocomplete
          class="ml-16 table-view-selector"
          [options]="selectorOptions"
          [value]="activeSelectorValue"
          (valueChange)="selectorValueChanged($event)"
          [showValidationStatus]="false"
          [clearable]="false"
        ></icz-form-autocomplete>
      }
    </div>
    <icz-tabs
      tableTabs
      class="grow"
      [tabs]="(tabs$ | async)!"
      [activeTab]="activeTab"
      [shouldNavigate]="false"
      (tabClick)="viewTabChanged($event)"
    ></icz-tabs>
    <ng-container noDataLabel>
      @if (!isUnitView && (activeViewMetadata!.tabView === EsslComponentTaskView.TO_SIGN || activeViewMetadata!.tabView === EsslComponentTaskView.TO_APPROVE)) {
        <div class="alldone-section">
          <icz-icon class="mt-16" svgIcon="alldone"></icz-icon>
          <icz-label class="mt-16 font-bold" label="Nemáte žádné úkoly"></icz-label>
          <icz-label class="mt-16" label="Vše jste úspěšně vyřešili :-)"></icz-label>
        </div>
      }
      @else {
        <icz-label label="Žádné úkoly"></icz-label>
      }
    </ng-container>
  </icz-circulation-table>
}
