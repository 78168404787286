import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EsslComponentTasksRoutingModule} from './essl-component-tasks-routing.module';
import {EsslComponentTasksComponent} from './essl-component-tasks.component';
import {SharedModule} from '|shared';

@NgModule({
  declarations: [EsslComponentTasksComponent],
  imports: [CommonModule, SharedModule, EsslComponentTasksRoutingModule],
})
export class EsslComponentTasksModule {}
