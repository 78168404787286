import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  castStream,
  CirculationSearchService,
  CurrentSessionService,
  DocumentsTableColumn,
  doneTaskResolutions,
  EsslComponentDto,
  EsslComponentSearchService,
  ExtendedCirculationTaskDtoWithFullBaseObject,
  getFullComponentsObs,
  inProgressTaskResolutions,
  startAssignedTasks,
  UnitViewSearchConfig
} from '|shared';
import {CirculationActivityType} from '|api/commons';
import {ApiDocumentService, DocumentDto} from '|api/document';
import {EsslComponentTaskView} from './essl-component-tasks.component';
import {ApiCirculationTaskSystemService} from '|api/flow';
import {FilterOperator, IczTableDataSource, Page} from '@icz/angular-table';
import {removeDuplicates} from '@icz/angular-essentials';

const ACTIVITY_TYPE_FIELD_NAME = 'activityType';

export class EsslComponentTasksDatasource extends IczTableDataSource<ExtendedCirculationTaskDtoWithFullBaseObject> {
  constructor(searchService: CirculationSearchService,
              systemTaskManagementService: ApiCirculationTaskSystemService,
              apiDocumentService: ApiDocumentService,
              currentSessionService: CurrentSessionService,
              esslComponentSearchService: EsslComponentSearchService,
              taskScreenView: EsslComponentTaskView,
              unitView: UnitViewSearchConfig,
  ) {
    super(searchParams => {
      if (!unitView.isUnitView) {
        searchParams.filter.push(
          {
            fieldName: DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
            operator: FilterOperator.equals,
            value: String(currentSessionService.currentUserFunctionalPosition!.id),
          }
        );
      }

      searchParams.filter.push(
        {
          fieldName: 'isManagementTask',
          operator: FilterOperator.equals,
          value: String(false),
        }
      );

      if (taskScreenView === EsslComponentTaskView.TO_SIGN) {
        searchParams.filter.push(
          {
            fieldName: ACTIVITY_TYPE_FIELD_NAME,
            operator: FilterOperator.inSet,
            value: CirculationActivityType.COMPONENT_SIGNING,
          },
        );
      }
      else if (taskScreenView === EsslComponentTaskView.TO_APPROVE) {
        searchParams.filter.push(
          {
            fieldName: ACTIVITY_TYPE_FIELD_NAME,
            operator: FilterOperator.inSet,
            value: CirculationActivityType.COMPONENT_APPROVAL,
          },
        );
      }

      if (taskScreenView === EsslComponentTaskView.TO_SIGN || taskScreenView === EsslComponentTaskView.TO_APPROVE) {
        searchParams.filter.push(
          {
            fieldName: 'resolution',
            operator: FilterOperator.inSet,
            value: String(inProgressTaskResolutions),
          },
        );
      }
      else if (taskScreenView === EsslComponentTaskView.TASKS_RESOLVED) {
        searchParams.filter.push(
          {
            fieldName: 'resolution',
            operator: FilterOperator.inSet,
            value: String(doneTaskResolutions),
          },
        );
      }

      return searchService.findTasksGlobally(searchParams, unitView).pipe(
        startAssignedTasks(
          currentSessionService,
          searchService,
          systemTaskManagementService,
          [EsslComponentTaskView.TO_APPROVE, EsslComponentTaskView.TO_SIGN],
          searchParams,
          taskScreenView,
          unitView,
        ),
        castStream<Page<ExtendedCirculationTaskDtoWithFullBaseObject>>(),
        switchMap(tasks => {
          const documentIds = removeDuplicates(
            tasks.content.map(documentTask => documentTask.documentId!).filter(Boolean)
          );

          let fullDocuments$: Observable<DocumentDto[]> = of([]);

          if (documentIds.length) {
            fullDocuments$ = apiDocumentService.documentGetByIds({body: documentIds});
          }

          const fullComponents$: Observable<EsslComponentDto[]> = getFullComponentsObs(tasks, esslComponentSearchService);

          return forkJoin([fullDocuments$, fullComponents$]).pipe(map(([documents, components]) => {
            tasks.content = tasks.content.map(t => {
              return {
                ...t,
                fullDocument: documents.find(d => d.id === t.documentId) ?? null,
                fullComponent: components.find(d => d.id === t.componentId) ?? null,
              };
            }) as ExtendedCirculationTaskDtoWithFullBaseObject[];
            return tasks;
          }));
        })
      );
    });
  }
}
