import {Component, inject, OnInit} from '@angular/core';
import {ApiDocumentService} from '|api/document';
import {ApiCirculationTaskSystemService} from '|api/flow';
import {
  AbstractUnitViewWithTabsComponent,
  BLANK_SEARCH_PARAMS,
  CirculationSearchService,
  CirculationView,
  createTabTableId,
  CurrentSessionService,
  EsslComponentSearchService,
  TableWithTabsViewMetadata
} from '|shared';
import {
  EsslComponentTasksDatasource
} from './essl-component-tasks.datasource';
import {BehaviorSubject, combineLatest, take} from 'rxjs';
import {deepClone, TabItem} from '@icz/angular-essentials';
import {SearchParams} from '@icz/angular-table';

export enum EsslComponentTaskView {
  TO_SIGN = 'toSign',
  TO_APPROVE = 'toApprove',
  TASKS_RESOLVED = 'resolved',
}

const NONUNIT_TASK_VIEW_TABS: TabItem<EsslComponentTaskView>[] = [
  {
    id: EsslComponentTaskView.TO_SIGN,
    label: 'K podepsání',
    showCount: true,
    count: 0,
  },
  {
    id: EsslComponentTaskView.TO_APPROVE,
    label: 'Ke schválení',
    showCount: true,
    count: 0,
  },
  {
    id: EsslComponentTaskView.TASKS_RESOLVED,
    label: 'Vyřešené úkoly',
  },
];

const UNIT_TASK_VIEW_TABS: TabItem<EsslComponentTaskView>[] = [
  {
    id: EsslComponentTaskView.TO_SIGN,
    label: 'K podepsání',
    showCount: true,
    count: 0,
  },
  {
    id: EsslComponentTaskView.TO_APPROVE,
    label: 'Ke schválení',
    showCount: true,
    count: 0,
  },
];

interface CirculationViewMetadata extends TableWithTabsViewMetadata<EsslComponentTaskView> {
  documentView: CirculationView;
}


@Component({
  selector: 'icz-essl-component-tasks',
  templateUrl: './essl-component-tasks.component.html',
  styleUrls: ['./essl-component-tasks.component.scss'],
})
export class EsslComponentTasksComponent extends AbstractUnitViewWithTabsComponent<EsslComponentTaskView, CirculationViewMetadata> implements OnInit {

  private currentSessionService = inject(CurrentSessionService);
  private searchService = inject(CirculationSearchService);
  private apiDocumentService = inject(ApiDocumentService);
  private taskSystemService = inject(ApiCirculationTaskSystemService);
  private esslComponentSearchService = inject(EsslComponentSearchService);

  tabs$!: BehaviorSubject<TabItem<EsslComponentTaskView>[]>;

  previousSearchParams: SearchParams = BLANK_SEARCH_PARAMS;

  protected viewsEnum = EsslComponentTaskView;
  protected defaultView = EsslComponentTaskView.TO_SIGN;

  get circulationTableView(): Nullable<CirculationView> {
    return this.activeViewMetadata?.documentView;
  }

  readonly EsslComponentTaskView = EsslComponentTaskView;

  override ngOnInit() {
    if (this.isUnitView) {
      this.tabs$ = new BehaviorSubject(UNIT_TASK_VIEW_TABS);
    }
    else {
      this.tabs$ = new BehaviorSubject(NONUNIT_TASK_VIEW_TABS);
    }

    super.ngOnInit();
  }

  refreshCounters(searchParams: SearchParams) {
    if (searchParams) {
      if (
        this.previousSearchParams.page !== searchParams.page &&
        this.previousSearchParams.size === searchParams.size
      ) {
        return;
      }
      else {
        this.previousSearchParams = searchParams;
      }
    }

    const toSignDatasource = this.getViewMetadata(EsslComponentTaskView.TO_SIGN, this.activeSelectorValue)!.dataSource;
    const toApproveDatasource = this.getViewMetadata(EsslComponentTaskView.TO_APPROVE, this.activeSelectorValue)!.dataSource;

    combineLatest([
      toSignDatasource.loadPageResult$,
      toApproveDatasource.loadPageResult$,
    ]).pipe(
      take(1),
    ).subscribe(([toSignPage, toApprovePage]) => {
      const tabsCopy = deepClone(this.tabs$.value);
      const toSignTab = tabsCopy.find(t => t.id === EsslComponentTaskView.TO_SIGN)!;
      const toApproveTab = tabsCopy.find(t => t.id === EsslComponentTaskView.TO_APPROVE)!;

      toSignTab.count = toSignPage.totalElements;
      toApproveTab.count = toApprovePage.totalElements;

      this.tabs$.next(tabsCopy);
    });

    toSignDatasource.loadPage(BLANK_SEARCH_PARAMS);
    toApproveDatasource.loadPage(BLANK_SEARCH_PARAMS);
  }

  protected getViewMetadata(tabView: EsslComponentTaskView, selectorValue: Nullable<number>): CirculationViewMetadata {
    switch (tabView) {
      case EsslComponentTaskView.TO_SIGN:
        return {
          tabView,
          documentView: CirculationView.COMPONENT_TASKS_TO_SIGN,
          dataSource: new EsslComponentTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.apiDocumentService,
            this.currentSessionService,
            this.esslComponentSearchService,
            EsslComponentTaskView.TO_SIGN,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(EsslComponentTaskView.TO_SIGN + 'essl-component-tasks'),
        };
      case EsslComponentTaskView.TO_APPROVE:
        return {
          tabView,
          documentView: CirculationView.COMPONENT_TASKS_TO_APPROVE,
          dataSource: new EsslComponentTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.apiDocumentService,
            this.currentSessionService,
            this.esslComponentSearchService,
            EsslComponentTaskView.TO_APPROVE,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(EsslComponentTaskView.TO_APPROVE + 'essl-component-tasks'),
        };
      case EsslComponentTaskView.TASKS_RESOLVED:
        return {
          tabView,
          documentView: CirculationView.COMPONENT_TASKS_DONE,
          dataSource: new EsslComponentTasksDatasource(
            this.searchService,
            this.taskSystemService,
            this.apiDocumentService,
            this.currentSessionService,
            this.esslComponentSearchService,
            EsslComponentTaskView.TASKS_RESOLVED,
            {isUnitView: this.isUnitView, orgUnitId: selectorValue}
          ),
          tableId: createTabTableId(EsslComponentTaskView.TASKS_RESOLVED + 'essl-component-tasks'),
        };
    };
  }

}
